import React from "react";
import Lottie from "react-lottie";
import { default as animationData } from "../../Lotties/Landing/splash.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Landing = () => (
  <div className="landings-header-wrapper">
    <div className="landings-header">
      <div className="content">
        <h1 className="desktop">
          Life changing creative <span>Nano MBAs from the world’s </span>
          <br />
          <span>best brands</span>
        </h1>
        <h1 className="mobile">
          Life changing creative Nano MBAs from the world’s best brands
        </h1>

        <h2>Make yourself irreplaceable</h2>
        <div className="cta">
          <a
            className="fl-button large highlight"
            data-smooth-scroll-to="#course-grid-popular-title"
            href="#"
          >
            Explore courses
          </a>
          <a
            className="fl-button large highlight view-pricing"
            data-smooth-scroll-to="#pricing-container"
            href="#"
          >
            View pricing
          </a>
        </div>
        <h3 className="partners-header">Certified by</h3>
        <div className="partners desktop-view">
          <img
            src={require("../../../../../assets/images/landings/partners/cannes.png")}
            alt="cannes"
            className="cannes"
          />
          <img
            src={require("../../../../../assets/images/landings/partners/ogilvy.png")}
            alt="ogilvy"
            className="ogilvy"
          />
          <img
            src={require("../../../../../assets/images/landings/partners/ana.png")}
            alt="ana"
            className="happiness"
          />
          <img
            src={require("../../../../../assets/images/landings/partners/warc.png")}
            alt="warc"
            className="warc"
          />
        </div>
        <div className="partners mobile-view">
          <div>
            <img
              src={require("../../../../../assets/images/landings/partners/cannes.png")}
              alt="cannes"
              className="cannes"
            />
            <img
              src={require("../../../../../assets/images/landings/partners/ogilvy.png")}
              alt="ogilvy"
              className="ogilvy"
            />
            <img
              src={require("../../../../../assets/images/landings/partners/ana.png")}
              alt="ana"
              className="happiness"
            />
            <img
              src={require("../../../../../assets/images/landings/partners/warc.png")}
              alt="warc"
              className="warc"
            />
          </div>
        </div>
      </div>
      <div className="splash">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  </div>
);

export default Landing;
